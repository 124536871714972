import { Button, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { createTable, getCoreRowModel, useTableInstance } from '@tanstack/react-table';
import { first, isEmpty, uniqBy, partition } from 'lodash';
import { type MouseEvent, type ReactElement, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { toast } from 'react-toastify';
import type { SetRequired } from 'type-fest';
import { MerchantTypenameToType } from '../../shared';
import { gqlClient } from '../../shared/gqlClient';
import type { EnrichedBankTransaction } from '../../types';
import { BulkCategorizationDialog, BulkCategorizationResult } from '../BulkCategorizationDialog';
import { CategorizationDialog, CategorizationResult } from '../CategorizationDialog';
import { BankTransactionsCSVDownloadButton } from './BankTransactionsCSVDownloadButton';
import { BankTransactionsDeleteButton } from './BankTransactionsDeleteButton';
import { BankTransactionsExcludeButton } from './BankTransactionsExcludeButton';
import { BankTransactionsTableSelectionSummary } from './BankTransactionsTableSelectionSummary';
import { useBankTransactionTableColumns } from './useBankTransactionTableColumns';
import { COMPANY_AFFILIATE_REQUIREMENTS } from '../../shared/classification-utils';
import { createClient, everything } from '../../__genql__/banks';
import { TokenService } from '../../services/auth/TokenService';
import { ConfigService } from '../../services/config';

export interface BankTransactionsTableProps {
  bankTransactions: EnrichedBankTransaction[];
  companyId?: string | null;
  hasMorePages?: boolean | null;
  isLoadingBankTransactions?: boolean | null;
  onBankTransactionsUpdated?: (
    bankTransactions: SetRequired<Partial<EnrichedBankTransaction>, 'id'>[],
    isFullUpdate?: boolean,
  ) => any;
  onBankTransactionsUpdating?: (bankTransactions: Pick<EnrichedBankTransaction, 'id'>[]) => void;
  onBankTransactionsDeleted?: (bankTransactions: Pick<EnrichedBankTransaction, 'id'>[]) => void;
  onBankTransactionsExcluded?: (bankTransactions: Pick<EnrichedBankTransaction, 'id'>[]) => void;
  onPageRequest?: () => void;
}

const banksClient = createClient({
  url: `${ConfigService.getInstance().getOrFail('BANKS_GQL_API')}`,
  headers: async () => {
    const token = await TokenService.getInstance().getToken();
    return { Authorization: `Bearer ${token}` };
  },
});

const allBankTransactionFields = {
  ...everything,
  bankAccount: { ...everything },
  merchant: { ...everything },
  businessEvent: { ...everything, classifications: { ...everything, askTheUserResult: { ...everything } } },
  userMemo: { ...everything },
};

const INTERNAL_TRANSFER_BUSINESS_EVENT = 'internal-transfer';

const table = createTable().setRowType<EnrichedBankTransaction>();

export function BankTransactionsTable(props: BankTransactionsTableProps): ReactElement {
  const {
    bankTransactions,
    isLoadingBankTransactions,
    onBankTransactionsUpdated,
    onBankTransactionsUpdating,
    onBankTransactionsDeleted,
    onBankTransactionsExcluded,
    onPageRequest,
    companyId,
  } = props;

  const [activeBankTransaction, setActiveBankTransaction] = useState<EnrichedBankTransaction | null>(null);
  const [isCategorizationDialogOpen, setIsCategorizationDialogOpen] = useState(false);
  const [isBulkCategorizationDialogOpen, setIsBulkCategorizationDialogOpen] = useState(false);

  const { inView, ref: pageRequestRowRef } = useInView({ root: null, rootMargin: '20px', threshold: 0 });

  useEffect(() => {
    if (inView) {
      onPageRequest?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const handleBulkCategorizationChange = async (result: BulkCategorizationResult) => {
    if (selectedRowCount === 0) return;

    if (!companyId) {
      throw new Error('companyId is required for classification');
    }

    setIsBulkCategorizationDialogOpen(false);

    try {
      onBankTransactionsUpdating?.(selectedBankTransactions);
      deselectBankTransactions(selectedBankTransactions);

      if (!result.businessEvent) {
        try {
          const { bulkCategorizeTransactions } = await banksClient.mutation({
            bulkCategorizeTransactions: {
              __args: {
                bulkCategorizeTransactionsArgs: {
                  bankTransactionIds: selectedBankTransactions.map(({ id }) => id),
                  companyId: props.companyId,
                  businessEvent: result.businessEvent,
                  merchant: result.merchant ? { id: result.merchant.id, type: result.merchant.type } : null,
                },
              },
              __scalar: true,
              bankTransaction: allBankTransactionFields,
            },
          });

          const failedUpdating = bulkCategorizeTransactions
            .filter(({ status }) => status === 'FAILURE')
            .map(({ bankTransaction }) => bankTransaction) as any;
          const succeededUpdating = bulkCategorizeTransactions
            .filter(({ status }) => status === 'SUCCESS')
            .map(({ bankTransaction }) => bankTransaction) as any;

          onBankTransactionsUpdated?.(failedUpdating);
          onBankTransactionsUpdated?.(succeededUpdating, true);

          if (failedUpdating.length > 0) {
            toast.error(
              `Failed to bulk categorize transactions: ${failedUpdating.length} out of ${selectedRowCount} transactions failed to update`,
            );
          }
        } catch (error: any) {
          console.error(error);
          onBankTransactionsUpdated?.(selectedBankTransactions);
          toast.error(`Failed to bulk categorize transactions: ${error.message}`);
        }

        return;
      }

      const { categorizeBankTransactions } = await gqlClient.mutation({
        categorizeBankTransactions: {
          error: true,
          bankTransaction: {
            __scalar: true,
            bankAccount: { __scalar: true },
            merchant: {
              __typename: true,
              on_CompanyAffiliate: {
                name: true,
                type: true,
                id: true,
              },
              on_Customer: {
                id: true,
                name: true,
              },
              on_Vendor: {
                id: true,
                name: true,
                logoUrl: true,
              },
              on_Institution: {
                id: true,
                name: true,
                logoUrl: true,
              },
            },
            businessEvent: { __scalar: true, classifications: { __scalar: true } },
          },
          __args: {
            input: {
              companyId,
              categorizations: selectedBankTransactions.map((bankTransaction) => ({
                bankTransactionId: bankTransaction.id,
                merchant: result.merchant ? { id: result.merchant.id, type: result.merchant.type } : null,
                classifications: [
                  {
                    amount: bankTransaction.amount,
                    businessEvent: result.businessEvent,
                  },
                ],
              })),
            },
          },
        },
      });

      const [successfullyClassifiedTransactions, failedClassifiedTransactions] = partition(
        categorizeBankTransactions,
        ({ error }) => error == null,
      );

      onBankTransactionsUpdated?.(failedClassifiedTransactions.map(({ bankTransaction }) => bankTransaction) as any);
      onBankTransactionsUpdated?.(
        successfullyClassifiedTransactions.map(({ bankTransaction }) => bankTransaction) as any,
        true,
      );

      if (failedClassifiedTransactions.length > 0) {
        toast.error(
          `Failed to bulk categorize transactions: ${failedClassifiedTransactions.length} out of ${selectedRowCount} transactions failed to update`,
        );
      }
    } catch (error: any) {
      console.error(error);
      onBankTransactionsUpdated?.(selectedBankTransactions);
      toast.error(`Failed to bulk categorize transactions: ${error.message}`);
    }
  };

  const handleBusinessMeaningCellClick = (event: MouseEvent | undefined, bankTransaction: EnrichedBankTransaction) => {
    event?.stopPropagation();
    event?.preventDefault();

    setActiveBankTransaction(bankTransaction);

    setIsCategorizationDialogOpen(true);
  };

  const handleCategorizationChange = async (result: CategorizationResult) => {
    if (!activeBankTransaction) return;

    setIsCategorizationDialogOpen(false);

    const pairedBankTransactions = getPairedOrWillBePairedBankTransactions(result);

    deselectBankTransactions([activeBankTransaction, ...pairedBankTransactions]);

    if (!companyId) {
      throw new Error('companyId is required for classification');
    }

    const classificationInput = getClassificationFromCategorizationInput(result);

    try {
      onBankTransactionsUpdating?.([activeBankTransaction, ...pairedBankTransactions]);

      const { categorizeBankTransactions: classificationResult } = await gqlClient.mutation({
        categorizeBankTransactions: {
          error: true,
          status: true,
          bankTransaction: {
            __scalar: true,
            bankAccount: { __scalar: true },
            merchant: {
              __typename: true,
              on_CompanyAffiliate: {
                name: true,
                type: true,
                id: true,
              },
              on_Customer: {
                id: true,
                name: true,
              },
              on_Vendor: {
                id: true,
                name: true,
                logoUrl: true,
              },
              on_Institution: {
                id: true,
                name: true,
                logoUrl: true,
              },
            },
            businessEvent: { __scalar: true, classifications: { __scalar: true } },
            userMemo: { __scalar: true },
          },
          __args: {
            input: {
              companyId,
              categorizations: [
                {
                  bankTransactionId: activeBankTransaction.id,
                  classifications: classificationInput,
                  ...(result.merchantResult.merchant?.id && {
                    merchant: {
                      id: result.merchantResult.merchant.id,
                      type: result.merchantResult.merchant.type,
                    },
                  }),
                },
              ],
            },
          },
        },
      });

      onBankTransactionsUpdated?.(
        [
          {
            ...classificationResult[0].bankTransaction,
            ...(classificationResult[0].bankTransaction.merchant?.id && {
              merchant: {
                ...classificationResult[0].bankTransaction.merchant,
                type: MerchantTypenameToType[classificationResult[0].bankTransaction.merchant.__typename],
              },
            }),
          },
        ] as any,
        true,
      );

      const errors = classificationResult.filter(({ error }) => error);
      if (!isEmpty(errors)) {
        throw new Error(errors.map(({ error }) => error).join(', '));
      }

      if (!result.businessEventResult.shouldApplyAlways && !result.merchantResult.shouldApplyAlways) {
        return;
      }

      if (result.businessEventResult.classifications.length > 1) {
        throw new Error('Creating rule is not supported for split');
      }

      if (!result.merchantResult.merchant) {
        throw new Error('Merchant is required for creating rule');
      }

      const firstClassification = first(classificationInput);
      if (!firstClassification) {
        throw new Error('Cannot create rule without classification');
      }

      const { upsertBankTransactionLocalRule: ruleResults } = await gqlClient.mutation({
        upsertBankTransactionLocalRule: {
          bankTransactionIdsAffectedByJobId: true,
          realTimeApplierJobId: true,
          __args: {
            input: {
              moneyDirection: activeBankTransaction.amount > 0 ? 'IN' : 'OUT',
              bankTransactionId: activeBankTransaction.id,
              classification: firstClassification,
              companyId,
              merchant: {
                id: result.merchantResult.merchant.id,
                type: result.merchantResult.merchant.type,
              },
              shouldAlwaysApplyMerchant: result.merchantResult.shouldApplyAlways,
              shouldAlwaysApplyClassification: result.businessEventResult.shouldApplyAlways,
            },
          },
        },
      });

      onBankTransactionsUpdating?.(ruleResults.bankTransactionIdsAffectedByJobId.map((id) => ({ id })));
    } catch (e: any) {
      console.error(e);
      onBankTransactionsUpdated?.([activeBankTransaction, ...pairedBankTransactions]);
      toast.error(`Failed to update transactions: ${e.message}`);
    }
  };

  const getPairedOrWillBePairedBankTransactions = (result: CategorizationResult): EnrichedBankTransaction[] => {
    const internalTransferFromNewCategorization = result.businessEventResult.classifications.find(
      ({ businessEvent }) => businessEvent === INTERNAL_TRANSFER_BUSINESS_EVENT,
    )?.pairedEntityId;

    const internalTransferFromCurrentCategorization = activeBankTransaction?.businessEvent?.classifications.find(
      ({ businessEvent }) => businessEvent === INTERNAL_TRANSFER_BUSINESS_EVENT,
    )?.pairedEntityId;

    return (
      [internalTransferFromNewCategorization, internalTransferFromCurrentCategorization].filter(Boolean) as string[]
    )
      .map((id) => bankTransactions.find((bankTransaction) => bankTransaction.id === id))
      .filter(Boolean) as EnrichedBankTransaction[];
  };

  const handleBulkCategorizeTransactions = async (event: MouseEvent | undefined) => {
    event?.stopPropagation();
    event?.preventDefault();

    if (!selectedRowCount) return;

    setIsBulkCategorizationDialogOpen(true);
  };

  const handleBankTransactionsDeleted = (bankTransactions: EnrichedBankTransaction[]) => {
    deselectBankTransactions(bankTransactions);
    setActiveBankTransaction(null);
    onBankTransactionsDeleted?.(bankTransactions);
  };

  const handleBankTransactionsExcluded = (bankTransactions: EnrichedBankTransaction[]) => {
    deselectBankTransactions(bankTransactions);
    setActiveBankTransaction(null);
    onBankTransactionsExcluded?.(bankTransactions);
  };

  const handleVendorCellClick = (event: MouseEvent | undefined, bankTransaction: EnrichedBankTransaction) => {
    event?.stopPropagation();
    event?.preventDefault();

    setActiveBankTransaction(bankTransaction);

    setIsCategorizationDialogOpen(true);
  };

  const deselectBankTransactions = (bankTransactions: Pick<EnrichedBankTransaction, 'id'>[]): void => {
    tableInstance.setRowSelection((rowSelection) => ({
      ...rowSelection,
      ...bankTransactions.reduce((acc, bankTransaction) => ({ ...acc, [bankTransaction.id]: false }), {}),
    }));
  };

  const { columns, rowSelection, setRowSelection } = useBankTransactionTableColumns({
    bankTransactions,
    handleBusinessMeaningCellClick,
    handleVendorCellClick,
    table,
    companyId: props.companyId,
  });

  const data = useMemo(() => {
    if (!isLoadingBankTransactions) {
      return bankTransactions;
    }

    return [
      ...bankTransactions,
      ...(new Array(100).fill(null).map((_, index) => ({
        id: `skeleton-${index}`,
        bankAccount: { id: 'skeleton' },
      })) as EnrichedBankTransaction[]),
    ];
  }, [bankTransactions, isLoadingBankTransactions]);

  const tableInstance = useTableInstance(table, {
    columns,
    data,
    enableMultiRowSelection: true,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (bankTransaction) => bankTransaction.id,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
  });

  const { rows: selectedRows } = tableInstance.getSelectedRowModel();
  const selectedBankTransactions = useMemo(() => selectedRows.map((row) => row.original!), [selectedRows]);

  const selectedRowCount = selectedRows.length;

  return (
    <>
      <Stack justifyContent="right" direction="row" spacing={2}>
        <BankTransactionsTableSelectionSummary selectedBankTransactions={selectedBankTransactions} />
        <BankTransactionsCSVDownloadButton bankTransactions={bankTransactions} />
        <Button disabled={!selectedRowCount} onClick={handleBulkCategorizeTransactions} variant="contained">
          Categorize transactions
        </Button>
        <BankTransactionsDeleteButton
          selectedBankTransactions={selectedBankTransactions}
          onBankTransactionsDeleted={handleBankTransactionsDeleted}
        />
        <BankTransactionsExcludeButton
          selectedBankTransactions={selectedBankTransactions}
          onBankTransactionsExcluded={handleBankTransactionsExcluded}
        />
      </Stack>
      <Table>
        <TableHead>
          {tableInstance.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableCell key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : header.renderHeader()}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {uniqBy(tableInstance.getRowModel().rows, 'id').map((row) => (
            <TableRow key={row.id} data-transaction-id={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>{cell.renderCell()}</TableCell>
              ))}
            </TableRow>
          ))}
          {!isLoadingBankTransactions && props.hasMorePages ? (
            <TableRow ref={pageRequestRowRef}>
              <TableCell align="center" colSpan={columns.length}>
                LOAD MORE...
              </TableCell>
            </TableRow>
          ) : undefined}
        </TableBody>
      </Table>

      {props.companyId && (
        <>
          <CategorizationDialog
            companyId={props.companyId}
            bankTransaction={activeBankTransaction}
            isOpen={isCategorizationDialogOpen}
            onClose={() => setIsCategorizationDialogOpen(false)}
            onCategorizationChange={handleCategorizationChange}
          />
          <BulkCategorizationDialog
            companyId={props.companyId}
            bankTransactions={selectedBankTransactions}
            isOpen={isBulkCategorizationDialogOpen}
            onClose={() => setIsBulkCategorizationDialogOpen(false)}
            onCategorizationChange={handleBulkCategorizationChange}
          />
        </>
      )}
    </>
  );
}

export function getClassificationFromCategorizationInput(categorizationRequest: CategorizationResult) {
  if (
    categorizationRequest.businessEventResult?.classifications == null ||
    categorizationRequest.businessEventResult?.classifications.length === 0
  ) {
    return null;
  }

  const classifications = categorizationRequest.businessEventResult?.classifications.map((classification) => {
    if (classification.businessEvent && COMPANY_AFFILIATE_REQUIREMENTS[classification.businessEvent]) {
      const merchant = categorizationRequest.merchantResult.merchant;
      if (!categorizationRequest.merchantResult.merchant?.id) {
        throw new Error('Merchant is required for company affiliate business events');
      }

      if (
        !COMPANY_AFFILIATE_REQUIREMENTS[classification.businessEvent].affiliateTypes.includes(
          categorizationRequest.merchantResult.merchant?.merchantSubtype ?? '',
        )
      ) {
        throw new Error(
          `Merchant ${merchant?.name} is not an holder which is required for ${classification.businessEvent}`,
        );
      }

      return {
        businessEvent: classification.businessEvent!,
        amount: classification.amount,
        pairedEntityId: categorizationRequest.merchantResult.merchant.id,
        pairedEntityType: 'COMPANY_AFFILIATE_BALANCE' as const,
        pairingType: 'MATCH' as const,
      };
    }

    return classification;
  });

  return classifications;
}
