import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Switch,
  Typography,
  Autocomplete as MUIAutocomplete,
  TextField,
} from '@mui/material';
import { yellow } from '@mui/material/colors';
import { DataGrid, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { capitalize, uniq, debounce, every } from 'lodash';
import { useEffect, useState, useMemo } from 'react';
import {
  BalanceTransaction,
  BalanceTransactionType,
  bankAffectingIntegrationEventsService,
} from '../../../../services/bank-affecting-integration-events';
import { CustomNoRowsOverlay } from '../../../../ui/NoRowsOverlay/NoRowsOverlay';
import { bankTransactionDisplayColumns } from '../bankTransactionDisplayColumns';
import type { ContentProps } from '../ContentSelector';
import { bankAffectingDisplayColumns } from './bankAffectingDisplayColumns';
import { addDays, addYears, startOfDay, subDays, subYears } from 'date-fns';
import { usePairedEntities } from '../../../../shared/usePairedEntities';
import { MatchedEntityDetails } from '../MatchedEntityDetails';
import { usePairedEntity } from '../../../BankTransactions/hooks/usePairedEntity';
import { IntegrationTransfer } from '../../../../services/bank-affecting-integration-events';
import { PairingType, PairedEntityType, enumIntegrationType } from '../../../../__generated-global__';

enum SortType {
  DATE,
  AMOUNT,
}

export const BankAffectingDisplay = ({
  bankTransaction,
  onBusinessEventSelectionChange,
  currentBusinessMeaning,
  eventTitle,
  type,
}: ContentProps & { eventTitle: string; type: BalanceTransactionType }) => {
  const { id, companyId, amountInUsd, postedAt } = bankTransaction;
  const { pairedEntities } = usePairedEntities(id, companyId);

  const [internalTransferCandidates, setInternalTransferCandidates] = useState<IntegrationTransfer[]>([]);
  const [visibleInternalTransferCandidates, setVisibleInternalTransferCandidates] = useState<IntegrationTransfer[]>([]);
  const [integrationTypes, setIntegrationTypes] = useState<string[]>([]);
  const [amountFilter, setAmountFilter] = useState<number | null>(null);
  const [integrationTypeFilter, setIntegrationTypeFilter] = useState<string | null>(null);
  const [isLoading, setIsLoadingCandidates] = useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [isExactMatchMode, setIsExactMatchMode] = useState(true);
  const [sortType, setSortType] = useState(SortType.AMOUNT);
  const [selectedPairedEntityId, setSelectedPairedEntityId] = useState<string | undefined>();

  const exactMatchFilters = {
    companyId,
    sort: {},
    amountRange: {
      from: amountInUsd < 0 ? -amountInUsd : amountInUsd,
      to: amountInUsd < 0 ? -amountInUsd : amountInUsd,
    },
    timeRange: {
      from: subDays(startOfDay(new Date(postedAt)), 12),
      to: addDays(startOfDay(new Date(postedAt)), 12),
    },
  };

  const inexactMatchFilters = {
    companyId,
    sort: {
      ...(sortType === SortType.AMOUNT && { closeToAmount: type === 'TOPUP' ? -amountInUsd : amountInUsd }),
      ...(sortType === SortType.DATE && { closeToDate: postedAt }),
    },
    amountRange: {
      from: amountInUsd < 0 ? amountInUsd * -0.95 : amountInUsd * 0.95,
      to: amountInUsd < 0 ? amountInUsd * -1.05 : amountInUsd * 1.05,
    },
    timeRange: {
      from: subYears(new Date(postedAt), 2),
      to: addYears(new Date(postedAt), 2),
    },
  };

  const { pairedEntity, isLoading: isPairedEntityLoading } = usePairedEntity({
    transactionId: bankTransaction.id,
    companyId: bankTransaction.companyId,
  });

  useEffect(() => {
    setSelectionModel((pairedEntities || []).map((entity) => entity.pairedEntityId));
  }, [pairedEntities]);

  useEffect(() => {
    loadBankAffectingCandidates();
  }, [bankTransaction?.id, isExactMatchMode, sortType]);

  const loadBankAffectingCandidates = async () => {
    setIsLoadingCandidates(true);
    setInternalTransferCandidates([]);

    const balanceTransactions = await bankAffectingIntegrationEventsService.getMatchableIntegrationTransfers(
      isExactMatchMode ? exactMatchFilters : inexactMatchFilters,
      type,
    );

    const balanceTransactionsFiltered = bankTransaction.source === enumIntegrationType.PAYPAL
      ? balanceTransactions.filter(bt => bt.integrationType !== enumIntegrationType.PAYPAL)
      : balanceTransactions

    setIntegrationTypes(uniq(balanceTransactionsFiltered.map((bt) => bt.integrationType)));
    setInternalTransferCandidates(balanceTransactionsFiltered);
    setIsLoadingCandidates(false);
  };

  const amountFilterChangeHandler = (event: any) => {
    let float: null | number = Number.parseFloat(event.target.value);
    if (Number.isNaN(float)) float = null;
    setAmountFilter(float);
  };
  const debouncedAmountFilterChangeHandler = useMemo(() => debounce(amountFilterChangeHandler, 400), []);

  // Stop the invocation of the debounced function
  // after unmounting
  useEffect(() => {
    return () => debouncedAmountFilterChangeHandler.cancel();
  }, []);

  useEffect(() => {
    if (!amountFilter && !integrationTypeFilter) {
      setVisibleInternalTransferCandidates([...internalTransferCandidates]);
      return;
    }

    const predicates: Function[] = [];
    if (amountFilter)
      predicates.push(
        (candidate: BalanceTransaction) => Math.floor(candidate.amountInUsd) === Math.floor(amountFilter),
      );
    if (integrationTypeFilter)
      predicates.push((candidate: BalanceTransaction) => candidate.integrationType === integrationTypeFilter);

    const visibleCandidates = internalTransferCandidates.filter((candidate) =>
      every(
        predicates.map((p) => p(candidate)),
        Boolean,
      ),
    );
    setVisibleInternalTransferCandidates(visibleCandidates);
  }, [amountFilter, integrationTypeFilter, internalTransferCandidates]);

  useEffect(() => {
    if (selectionModel.length === 0 || setSelectedPairedEntityId == null) return;

    onBusinessEventSelectionChange({
      shouldApplyAlways: false,
      pairedEntityId: selectedPairedEntityId,
      pairedEntityType: 'BANK_AFFECTING_INTEGRATION_EVENT',
      pairingType: 'MATCH',
      businessMeanings: currentBusinessMeaning
        ? [{ businessMeaning: currentBusinessMeaning, amount: bankTransaction.amount }]
        : [],
    });
  }, [selectionModel, setSelectedPairedEntityId]);

  return (
    <>
      <Box display="flex" flexDirection="column" mt={4} sx={{ marginTop: '10px' }}>
        <Typography sx={{ fontWeight: 600, marginBottom: 2 }}>{capitalize(eventTitle)} for</Typography>
        <div style={{ height: 110, width: '100%' }}>
          <DataGrid
            rows={[bankTransaction]}
            columns={bankTransactionDisplayColumns}
            pageSize={1}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            hideFooter
          />
        </div>
      </Box>
      {pairedEntity && (
        <Box>
          <MatchedEntityDetails pairedEntity={pairedEntity}></MatchedEntityDetails>
        </Box>
      )}
      <Box display="flex" flexDirection="column" mt={4} sx={{ marginTop: '10px' }}>
        <Typography sx={{ fontWeight: 600, marginBottom: 2 }}>
          Please select a {eventTitle} to complete the manual match
        </Typography>
        <FormGroup style={{ flexDirection: 'row' }}>
          <FormControlLabel
            control={<Switch onChange={() => setIsExactMatchMode(!isExactMatchMode)} />}
            label={`Show not only exact match (fee) ${eventTitle}s`}
          />
          {!isExactMatchMode && (
            <FormControl>
              <InputLabel id="sort-balance-transactions">Sort By</InputLabel>
              <Select<SortType>
                labelId="sort-balance-transactions"
                id="demo-simple-select"
                value={sortType}
                label="Age"
                defaultValue={SortType.AMOUNT}
                onChange={({ target: { value: selectedSort } }) => setSortType(selectedSort as SortType)}
              >
                <MenuItem value={SortType.AMOUNT}>Closest amount</MenuItem>
                <MenuItem value={SortType.DATE}>Closest date</MenuItem>
              </Select>
            </FormControl>
          )}
        </FormGroup>
        <Box
          sx={{
            height: 250,
            width: '100%',
            '& .bank-affecting-row--paired': {
              bgcolor: () => yellow[200],
              '&:hover': {
                bgcolor: () => yellow[200],
              },
            },
          }}
        >
          <Box sx={{ padding: '10px 0' }}>
            <MUIAutocomplete
              sx={{ width: '300px', display: 'inline-block', marginRight: '15px' }}
              disablePortal
              options={integrationTypes}
              title="Integration type"
              onChange={(e, newValue) => setIntegrationTypeFilter(newValue)}
              value={integrationTypeFilter}
              renderInput={(params) => <TextField {...params} label="Integration type" />}
            />
            <TextField label="Amount" variant="outlined" onChange={debouncedAmountFilterChangeHandler} />
          </Box>
          <DataGrid
            sx={{ height: '650px' }}
            components={{
              LoadingOverlay: LinearProgress,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            loading={isLoading}
            rows={visibleInternalTransferCandidates}
            columns={bankAffectingDisplayColumns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            selectionModel={selectionModel}
            disableSelectionOnClick
            checkboxSelection
            hideFooterSelectedRowCount
            onSelectionModelChange={(selection: GridSelectionModel) => {
              let resultId: GridRowId | undefined;
              if (selection.length > 1) {
                const selectionSet = new Set(selectionModel);
                const result = selection.filter((s) => !selectionSet.has(s));
                resultId = result[0];
                setSelectionModel(result[0] ? [result[0]] : []);
              } else {
                resultId = selection[0];
                setSelectionModel(selection);
              }

              setSelectedPairedEntityId(resultId as string | undefined);
            }}
            getRowClassName={(params) =>
              `bank-affecting-row--${params.row.pairedBankTransaction?.id ? 'paired' : 'none'}`
            }
            isRowSelectable={() => !isLoading}
          />
        </Box>
      </Box>
    </>
  );
};
